.menu-container{
    background-color: rgb(240, 244, 255);
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
}

.menu-opciones-wrapper{
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 35px;
    margin-bottom: 100px;
    margin-top: 10px;
}

.menu-opcion-container{
    background-color: white;
    border-radius: 4px;

    width: 255px;
    height: 80px;
    box-shadow: 0px 5px 8px rgba(0, 70, 111, 0.214);
    display: flex;    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease;
    text-overflow: clip;
}

.menu-opcion-container:hover{
    transform: translateY(-5%);
    background-color: var(--primary);
    color: white;
    cursor: pointer;
}