.add-inmueble-checkbox-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    
    padding-left: 8px;
    
    height: 40px;
    width: 45%;
}