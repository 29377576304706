.vender-container{

    scroll-behavior: smooth !important;
}

.vender-first-section{
    /* background: linear-gradient(90deg, #7001ba1b, #011aba2b), url('../../assets/img/vender-first-section-bg.jpg'); */
    background: linear-gradient(to bottom, #020c2e, #011aba1d), url('../../assets/img/vender-first-section-bg-min.jpg');
    height: fit-content;
   
    padding-block: 100px;
    padding-top: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    scroll-behavior: smooth !important;
    
    
}

.vender-first-section-title, .vender-first-section-subtitle{
    color: white;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.644);
    margin-bottom: 30px;
}

.vender-first-section-subtitle{
    max-width: 75%;
    text-shadow: 0px 3px 3px black;
    color: white;
    text-align: center;
    font-size: 18px;
}

.vender-third-subsection-2-title{
    font-weight: 600;
}


.vender-form-container{
    background-color: rgba(255, 255, 255, 0.518);
    width: 60%;
    
    height: 580px;
    backdrop-filter: blur(3px);
    border-radius: 12px;
    padding: 14px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.337);
    display: flex;
    column-gap: 10px;
    -webkit-column-gap: 10px;
    
}

.img-form-left-wrapper{
    height: 100%;
    max-width: 450px;
    border-radius: 12px;
    overflow: hidden;
}

.img-form-left{
    height: 150%;
    width: 150%;
    object-fit: cover;
    object-position: center;
    transition: 0.3s ease;
    transform: scale(1.5);
}

.img-form-left:hover{
    /* filter: grayscale(1.5%); */
    transform: scale(1.45);

}

.vender-second-section{
    background: linear-gradient(90deg, #7001ba2b, #011aba65), url('../../assets/img/vender-second-section-bg-min.jpg');
    height: 87vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;


    height: fit-content;
   
    padding-block: 130px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.vender-form-title{
    text-align: center;
    color: var(--primary);
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.696);
    margin-bottom: 10px;
    font-weight: 600;
}

.vender-form-wrapper{
    width: 60%;
    padding-inline: 3%;
    padding: 20px;
}

.btn-solicitar{
    margin: auto;
}

.vender-third-section{
    /* background: linear-gradient(90deg, #7001ba1b, #011aba2b), url('../../assets/img/vender-first-section-bg.jpg'); */
    /* background: linear-gradient(to bottom, #020c2e, #011aba1d), url('../../assets/img/vender-first-section-bg.jpg'); */
    background: radial-gradient(#fff,rgba(159, 165, 189, 0.585));
    height: fit-content;
   
    padding-block: 100px;
    padding-inline: 13%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    
    justify-content: space-between;
    align-items: center;
    column-gap: 12%;
    
    
    
}

.vender-third-subsection-1{
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    
}

.vender-third-subsection-2{
    width: fit-content;
    /* background-color: red; */
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 50px;
}

.vender-third-subsection-2-content{
    text-align: center;
}

.vender-third-subsection-2-btn{
    font-size: 20px;
    padding: 20px;
}

@media screen and (max-width: 1440px) {
    .vender-form-container{
        width: 75%;
    }

    
}

@media screen and (max-width: 1024px) {
    .vender-form-container{
        display: flex;
        flex-direction: column;
        height: fit-content;
        
    }

    .img-form-left-wrapper{
        height: 300px;
        width: 100%;
        max-width: 100%;
        display: none;
    }

    .vender-form-wrapper{
        width: 100%;
        
    }
}

@media screen and (max-width: 768px) {

    .vender-first-section-title{
        font-size: 28px;
    }

    .vender-first-section-subtitle{
        font-size: 16.5px;
    }

    .vender-third-section{
        display: flex;
        flex-direction: column;
    }

}

@media screen and (max-width: 425px) {

    .vender-first-section-title , .vender-third-subsection-2-title{
        font-size: 22.5px;
        text-align: center;
    }


    

    .vender-first-section-subtitle{
        font-size: 15.5px;
    }

    .vender-third-subsection-2-content{
        font-size: 14px;
    }


    .captcha{
        
        transform: scale(0.8);
        transform-origin: center center ;
    }

    .vender-form-wrapper{
        padding: 3px;
    }

}
@media screen and (max-width: 375px) {

.vender-form-title{
    font-size: 20px;
}


    
}



@media screen and (max-width: 320px) {

    .vender-first-section-title , .vender-third-subsection-2-title{
        font-size: 22px;
        text-align: center;
    }

    .vender-first-section-subtitle{
        font-size: 13.5px;
    }

    .captcha{
        
        transform: scale(0.7);
        transform-origin: center center ;
    }

    .vender-third-subsection-2-title{
        margin-top: 35px;
    }

    .vender-third-subsection-2-content{
        font-size: 13px;
    }

    .vender-form-title{
        font-size: 20px;
    }


}

