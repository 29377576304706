.blog-container{
    

background: radial-gradient(#fff,rgba(159, 165, 189, 0.585));
    width: 100%;
    padding-inline: 17.5%;
    min-height: 88vh;
    padding-block: 50px;
}

.blog-h1{
    color: var(--primary);
    font-size: 45px;
    
    width: 100%;
    margin-bottom: 35px;
    
}


@media screen and (max-width: 1000px) {
    .blog-container{
        padding-inline: 15%;
    }

    
}

@media screen and (max-width: 530px) {
    .blog-container{
        padding-inline: 10%;
    }

    .blog-h1{
        font-size: 30px;
    }
}