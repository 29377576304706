.servicios-container{
    
    
}

.first-section, .servicios-section, .valores-section{
    padding-inline: 12%;
    padding-block: 13vh;
    
    width: 100%;
    background-color: rgb(255, 255, 255);
    
}

.video-container{
    padding: 0;
    border-radius: 5px;
    margin: auto;
    width: 100%;
    margin-top: 75px;
    
    
    /* background: linear-gradient(135deg, rgb(174, 231, 244), rgb(74, 2, 230) ); */
    height: auto;

}

.video-presentacion{
    width: 100%;
    height: auto;
    padding: 0;
}

.valores-section{
    background-color: rgba(236, 243, 248, 0.715);
}

.servicios-section{
    /* background-color: #d90429; */
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 50;
}



.servicios-h1{
    color: var(--primary);
    font-size: 45px;
      
    width: 100%;
}

.pruebasColores{
    color: #022949; 
}

.first-subsection-container{
    margin: auto;
    width: 100%;
  
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    column-gap: 60px;
    line-height: 1.5rem;
    font-size: 18px;
    
}
.first-subsection{
    
    max-width: 46%;
    text-align: justify;
}

.second-subsection-container{
    width: 100%;
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(350px,1fr));
    row-gap: 35px;
    column-gap: 35px;
    justify-items: center;
    z-index: 5;
    
}



.servicio-card-container{
    
    background: linear-gradient(135deg, rgba(180, 209, 255, 0.701), rgba(255, 255, 255, 0.932), rgba(221, 233, 250, 0.615) );
    box-shadow: 0px 10px 10px rgba(8, 52, 124, 0.186);
    border-radius: 15px;
    width: 380px;
    display: flex;
    padding: 16px;
    column-gap: 12px;
    height: 200px;
    transition: 0.3s ease;
}

.servicio-card-container:hover{
    transform: translateY(-10px);
}



.valores-subsection-container{
    width: 100%;
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(310px,1fr));
    row-gap: 35px;
    column-gap: 15px;
    justify-items: center;
    /* background-color: red; */
}

.valores-card-container{
    width: 340px;
    
    padding: 8px;
    column-gap: 12px;
  
}

.valores-img-wrapper{
    height: 180px;
    width: 100%;
    margin-bottom: 20px;
  
}

.valores-img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 5px;
    filter: brightness(0.8);
    transition: 0.2s ease;
}


.valores-img:hover{
    filter: blur(0.8);
}

.valores-titulo{
    color: var(--secondary);
}

.valores-card-texto{
    text-align: justify;
    text-justify: inter-character;
    font-size: 15px;
    width: 100%;
}

.servicio-card-title{
    color: var(--secondary);
    
}

.column-icon{
    /* background-color: aqua; */
    max-width: 80px;
    display: flex;
    justify-content: center;
}

.servicio-icon{
    height: 65px;
}

.column-servicios-content{
    /* background-color: forestgreen; */
    max-width: 280px;

    padding: 5px;

}

.column-servicios-texto{
    margin-top: 15px;
    font-size: 15px;
    /* text-justify: inter-character !important;
    text-align: justify; */
    
}

.servicios-subtitulo{
    margin-top: 30px;
    font-size: 19px;

}


.custom-shape-divider-bottom-1664896550 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: 2;
}

.custom-shape-divider-bottom-1664896550 svg {
    position: relative;
    display: block;
    width: calc(170% + 1.3px);
    
    height: 698px;
    transform: rotateY(180deg);
    
}

.custom-shape-divider-bottom-1664896550 .shape-fill {
    fill: var(--turquesa);
    
    
}


@media screen and (max-width: 850px) {

    .first-subsection{
    
        max-width: 100%;
        text-align: justify;
        text-justify: inter-character;
    }

    .valores-subsection-container{
        justify-content: center;
        row-gap: 55px;
        grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
    }

    .valores-card-container{
        width: 100%
        
    }


    .servicio-card-container{
        width: 100%;
        height: 210px;
        
    }

    .column-icon{
        width: 20%;
    }

    .second-subsection-container{
        justify-content: center;
        grid-template-columns: repeat(auto-fit,minmax(270px,1fr));
        row-gap: 55px;
        column-gap: 30px;
    }

    .servicio-icon{
        height: 50px;
    }

    
    .column-servicios-content{
        width: 100%;
        max-width: fit-content;
    }

    .servicio-card-title{
        font-size: 16px;
    }

    .column-servicios-texto{
        font-size: 13px;
    }
   
}