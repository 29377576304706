.App {
  min-height: 100vh;
  min-width: 100%;
  
}


nav {
    height: 110px;
   
    background-color: var(--primary);
    /* position: relative; */
    
}

.upper-nav{
  background-color: var(--secondary);
  height: 25%;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: center;
padding-inline: 20px;
color: white;

}


.lower-nav{
  height: 75%;
  padding-inline: 100px;
  padding-left: 150px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 5px;
}

#logo-ga{
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  height: 100%;
  width: auto;
  max-width: 80px;
}

#burger-menu-link{
  
  height: 60%;
  transition: 0.5s ease;
}

/* #burger-menu-link{
  transform: rotateZ(-90deg);
} */


#burger-menu-icon{
  height: 100%;
}

#react-burger-menu-btn{
  cursor: auto !important;
  height: 0 !important;
}



.span-upper-nav{
  
  font-size: 15px;
  min-width: fit-content;
  width:fit-content;
  max-width: 400px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  font-family: 'Poppins', sans-serif !important;
  
}

.nav-links{
  
  display: flex;
  column-gap: 20px;
  -webkit-column-gap: 20px;
}

.a{
  height: 100%;
  
  display: flex;
  display: -webkit-flex;
  width: fit-content;
  margin-inline: 5px;
}

.icon-upper-nav{
  height: 20px
  
  
}

.Link ,.nav-link{
    text-decoration: none;
    color: white;
    letter-spacing: 0.5px;
    margin-inline: 5px;
    position: relative;
    
}



.nav-link::after{
  content: "";
  position: absolute;
  background-color: aliceblue;
  height: 2px;
  width: 0%;
  left: 0;
  bottom: -6px;
  transition: 0.3s;

}

.nav-link:hover::after{
  width: 100%;
  font-weight: 600;
}

footer {
    height: fit-content;
    background-color: var(--secondary);
    max-width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    padding: 70px;
}

.logos-container{
  background-color: aliceblue;
  height: fit-content;
  width: 100%;
  padding-block: 20px;
  padding-inline: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
}

.logos-img{
  height: 50px;
  width: auto;
}

select{
  height: 34px;
  width: 140px;
  margin-top: 5px;
  padding: 6px !important;
}

input, textarea, input[type="checkbox"], select{
  padding: 8px;
  outline: none;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 4px;
}

input[type="checkbox"]{
  height: 25px;
  width: 25px;
  margin-block: 2.5px;
}

input[type="checkbox"]:focus{
  background-color: #fbfbff;
  box-shadow: 0px 0px 3px var(--secondary);
}

input:focus, input[type="checkbox"]:focus, textarea:focus, select:focus {
  border: 1px solid var(--secondary);
  background-color: #fbfbff;
}

.generic-btn{
  padding: 8px;
  outline: none;
  border: none;
  background-color: var(--turquesa);
  border-radius: 8px;
  color: white;
  font-weight: 600;
  display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: 0.2s ease;
}

.generic-btn:focus, .generic-btn:hover{
  transform: scale(1.02);
  background-color: var(--turquesa-sec);
}

.leaflet-container{
  width: 100%;
  height: 100%;
}

.modal-burger{
  top: 110px;
  left: 100%;
  width: 40%;
  height: 90%;
  background-color: var(--primary);
  position: fixed;
  outline: none;
  z-index: 1000000000;
  transform: translateX(-100%);
  transition: 3.5 ease;
}

.modal-burger:active{
  transform: translateX(0);
}

.nav-link-burger{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-inline: 0 ;
  align-items: center;
  z-index: 1000000000;
  position: relative;
}

.nav-link-burger{
  padding: 40px;
  outline: none;
}

.nav-link-burger:hover{
  outline: none;
}

.nav-link-burger:active{
  background-color: var(--secondary);
}

.line{
  background-color: rgba(240, 248, 255, 0.486);
  height: 1px;
  width: 85%;
  margin: auto;
}

/* apartado burger menu */
#side-burger-menu{
  
  
}

.bm-burger-bars{
  height: 0% !important;
  display: none !important;
}

/* Media queries   */

/* PM - 1440px */
@media screen and (max-width: 1440px) {
   

 
}

@media screen and (max-width: 1024px) {
 
  
}

@media screen and (max-width: 850px) {

  .span-upper-nav{
    font-size: 12px;
  }

  #direccion{
    display: none;
  }

  .logos-container{
    gap: 15px;
    
  }

  .logos-img{
    height: 45px;
  }

  .nav-links{
    display: none;
    
  }

  .lower-nav{
    justify-content: space-between;
    
    padding-inline: 7%;
    padding-left: 10%;
  }

  nav{
    position: sticky;
      top: 0;
      z-index: 200000;
  }
  
}

@media screen and (min-width: 851px){
  #burger-menu-link{
    display: none;
  }

  .modal-burger{
    display: none;
  }
  
}

@media screen and (max-width: 850px) {
  .modal-burger{
    width: 60%;
    z-index: 1;
  }
}

@media screen and (max-width: 425px) {

 
  .modal-burger{
    width: 70%;
    z-index: 1;
  }

  .upper-nav{
    justify-content: space-between;
  }

  .icon-upper-nav{
    height: 16px;
  }

  .logos-img{
    height: 35px;
  }

  .lower-nav{
    padding-inline: 5%;
  }
  
  
}

@media screen and (max-width: 375px) {

  .upper-nav{
    justify-content: center;
  }

 #fuentes-contacto{
   display: none;
 }

 /* #rrss{
   display: none;
 }
   */
}

@media screen and (max-width: 320px) {

  
}
