.home-container {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.header-section {
    display: -webkit-flex;

    background-size: cover !important;

    background-position: center !important;
    background-repeat: no-repeat !important;

    height: 72.5vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    background-image: -webkit-linear-gradient(
            to bottom,
            #3731923a,
            rgba(0, 27, 34, 0.658)
        ),
        url("../../assets/img/fondoHome-min.jpg");
    /* background-image: linear-gradient(to bottom, #3731923a, rgba(0, 27, 34, 0.658)), url('https://pix10.agoda.net/geo/city/199604/1_199604_04.jpg?s=1920x822'); */
    background-image: linear-gradient(
            to bottom,
            #3731923a,
            rgba(0, 27, 34, 0.658)
        ),
        url("../../assets/img/fondoHome-min.jpg");
    background-attachment: fixed;
}

.header-title {
    color: rgb(255, 255, 255);

    font-size: 40px;
    font-family: "Poppins", sans-serif;

    text-shadow: 0px 10px 10px black;
    margin-top: 190px;
}

.destacados-section {
    width: 100%;
    margin: auto;
    padding-block: 110px;
    padding-bottom: 125px;
    /* background-color: #f0f8ff; */
    background: radial-gradient(#fff, rgba(159, 165, 189, 0.585));
}

.destacados-section-title {
    width: fit-content;
    margin: auto;
    color: var(--primary);
}

.destacados-sliders-container {
    display: flex;
    flex-wrap: wrap;
}

.equipo-section {
    height: fit-content;
    width: 100%;

    background-repeat: no-repeat;

    /* background: url(https://www.garrotxaactiva.cat/assets/1-1-49-1-1185x444.jpg); */
    background: linear-gradient(to right, #00173b, #003a91ab, #00173b),
        url(https://www.garrotxaactiva.cat/assets/1-1-49-1-1185x444.jpg);
    background-position: right 50% top 100%;
    background-size: 165%;
    background-attachment: fixed;
    padding: 110px;
}

.equipo-parallax-bg {
    background-position: center;
    background: linear-gradient(to bottom, #fff, #fef);
}

.provisional {
    background-color: var(--primary);
    height: 120px;
    width: 600px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.equipo-titulo {
    width: fit-content;
    margin: auto;
    text-shadow: 0px 6px 8px rgb(3, 0, 39);
    color: white;
}

.equipo-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 8vw;
    row-gap: 80px;
    flex-wrap: wrap;

    padding-block: 40px;
}

.equipo-profile-img {
    height: 300px;

    filter: drop-shadow(0px 10px 8px #01101f9f);
}

.equipo-container {
    width: 465px;

    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 30px; */
    /* background-color: var(--primary); */
    /* border-radius: 12px;
    border: 3px solid #007bac;
    box-shadow: 0px 3px 7px rgb(7, 7, 58); */
}

.equipo-description {
    background-color: var(--primary);
    color: white;
    border-radius: 5px;
    /* border: 2px solid #007bac; */
    border: 2px solid #caf0ff;
    padding: 25px;
    box-shadow: 0px 3px 7px #07073a;
    min-height: 180px;
    letter-spacing: 0.5px;
}

.modal-window {
    /* position:absolute;
    width: 700px;
    height: fit-content;
    overflow-y: scroll;
    background-color: rgb(240, 248, 255);
    border-radius: 5px;
    padding: 16px 32px 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    /* opacity: 80%; */
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    outline: none; */
    /* transition: opacity 3.5s ease; */
    outline: none;
}

.busqueda-avanzada-link {
    margin-top: 5px;
    width: 678px;
    display: flex;
    height: fit-content;
    justify-content: flex-end;

    font-size: 22px;
    text-shadow: 0px 10px 5px black;
    font-weight: 600;
}

#filtros-home-container {
    background: transparent;
    position: absolute;
    width: 90%;
    min-height: 300px;
    overflow-y: auto;
    background-color: rgb(240, 248, 255);
    border-radius: 5px;
    padding: 16px 32px 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* opacity: 80%; */
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    outline: none; */
    /* transition: opacity 3.5s ease; */
    outline: none;
}

#filtros-home-subcontainer {
    padding: 0px;
    padding-top: 25px;
}

.video-promo-section {
    width: 90%;
    height: auto;
    margin: auto;
    padding-block: 110px;
    padding-bottom: 125px;
}

.video-promo-section-home {
    height: auto;
    width: 100%;
    margin: auto;
    padding-block: 110px;
    padding-bottom: 110px;

    background-color: #f0f8ff;
}

.video-presentacion-container {
    position: relative;
    padding-top: 00px;
    padding-bottom: 50%;
    height: 0;
    margin-inline: 7%;
}

.video-presentacion-home {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: url("../../assets/img/laptop-frame-final.png") center center
        no-repeat;
    -webkit-filter: drop-shadow(0px 10px 7px #626269);
    filter: drop-shadow(0px 10px 7px #626269);
    background-size: 89.5% 82.5%;
    padding-top: 7.7%;
    padding-left: 14.3%;
    padding-right: 14.3%;
    padding-bottom: 10.3%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
}

.numero-formato {
    font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 1024px) {
    .equipo-container {
        width: 300px;
    }
    .equipo-description {
        width: 100%;
        font-size: 13px;
    }

    .equipo-profile-img {
        height: 250px;
    }

    .header-section {
        background-attachment: scroll;
    }
}

@media screen and (max-width: 806px) {
    #filtros-home-third-subsection {
        column-gap: 30px;
        -webkit-column-gap: 30px;
        max-width: 100%;
    }

    #filtros-third-section {
        width: 100%;
    }

    #filtros-first-section {
        width: 100%;
    }

    .filtros-second-section {
        width: 100%;
    }

    .equipo-container {
        width: 300px;
    }
    .equipo-description {
        width: 100%;
        font-size: 13px;
    }

    .equipo-profile-img {
        height: 250px;
    }
}

@media screen and (max-width: 700px) {
    .filtros-third-subsection {
        column-gap: 20px;
        -webkit-column-gap: 20px;

        /* justify-content: flex-start; */
    }
    .filtros-third-section {
        width: 100%;
    }

    .filtros-first-section {
        width: 100%;
    }

    .filtros-second-section {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .header-section {
        justify-content: center;
        padding-bottom: 27%;
    }

    .header-title {
        font-size: 33px;
        margin-inline: 10px;
        text-align: center;
        text-shadow: 0px 10px 10px rgba(0, 0, 0, 0.55);
    }

    .busqueda-avanzada-link {
        width: 430px;
    }
}

@media screen and (max-width: 450px) {
    .header-title {
        font-size: 28px;
        margin-inline: 10%;
        text-align: center;
        text-shadow: 0px 10px 10px rgba(0, 0, 0, 0.55);
    }

    .header-section {
        justify-content: center;
        padding-bottom: 45%;
    }

    .busqueda-avanzada-link {
        width: 88%;
        font-size: 17px;
    }

    .destacados-section-title {
        font-size: 25px;
    }

    .equipo-titulo {
        font-size: 25px;
    }

    #filtros-home-container {
        width: 95%;
        padding: 12px 12px 12px;
        overflow-y: auto;
        height: 65vh;
    }

    .equipo-section {
        padding-inline: 10%;
    }

    .equipo-description {
        width: 100%;
        font-size: 12.5px;
        padding: 15px;
    }

    .equipo-profile-img {
        height: 200px;
    }

    .equipo-container {
        width: 260px;
    }
}

@media screen and (max-width: 395px) {
    #filtros-home-container {
        width: 100%;
        border-radius: 0;
    }
}
