.footer-container{
    
    width: fit-content;
    height: fit-content;
    display: flex;
    
    justify-content: space-evenly;
    align-items: flex-start;
    column-gap: 100px;
    row-gap: 50px;
    font-size: 14px;
    flex-wrap: wrap;

    -webkit-column-gap: 100px;
    row-gap: 50px;
    font-size: 14px;
    flex-wrap: wrap;

}

.info-subcontainer{
   
    width: fit-content;
    
}

.footer-icons{
    height: 22px;
}

.footer-row{
    margin-bottom: 12px;
    display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Poppins', sans-serif;

    -webkit-column-gap: 8px;
    
   
}

.link-footer{
    margin: 0;
    
}

.footer-icons-row{
    display: flex;
    column-gap: 8px;
    -webkit-column-gap: 8px;
}

#idioma-subcontainer{
    width: fit-content;
}

@media screen and (max-width: 855px) {

    .info-subcontainer{
        width: 210px;
    }
}


@media screen and (max-width: 620px) {

    #idioma-subcontainer{
        width: 210px;
    }

   
}