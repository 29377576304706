.entrada-container{

}

.vista-grande{
    height: 220px;
    display: flex;
    /* flex-wrap: wrap; */
    margin-bottom: 50px;
    background-color: rgb(255, 255, 255);
    
    border-top-left-radius: 45px;
    border-bottom-right-radius: 45px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 10px 7px #d7e0ead3;
    transition: 0.2s ease;
    cursor: pointer;
    -webkit-transition: 0.2s ease;
}

.vista-grande:hover{
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    background-color: var(--secondary);   
}

/* .entrada-container:hover  .entrada-img{
    filter: blur(0px);
} */


.vista-pequeña{
    
    display: none;
    /* flex-wrap: wrap; */
    margin-bottom: 50px;
    background-color: rgb(255, 255, 255);
    
    border-top-left-radius: 45px;
    border-bottom-right-radius: 45px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
    
    box-shadow: 0px 10px 7px #d7e0ead3;
    transition: 0.2s ease;
    -webkit-transition: 0.2s ease;
    cursor: pointer;
}

.vista-pequeña:hover{
    transform: translateY(-2px);
    background-color: var(--secondary);   
    color: white;

    

}



.entrada-img-wrapper{
    height: 100%;
    
    
    position: relative;
    
}

.vp-entrada-img-wrapper{
    height: 220px;
    
    
    position: relative;
}

.entrada-img{
    /* filter: blur(0.5px); */
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    min-width: 270px;
    

}

.vp-entrada-img{
 /* filter: blur(0.5px); */
 object-fit: cover;
 object-position: center;
 height: 100%;
 width: 100%;
 min-width: 270px;
}

.entrada-content{
    min-width: 350px;
    padding-inline: 30px;
    padding-block: 20px;
    display: flex;
    flex-direction: column;
    
   

}

.vp-entrada-content{
    min-width: 270px;
    padding-inline: 30px;
    padding-block: 20px;
    display: flex;
    flex-direction: column;
}

.vp-entrada-titulo{
    width: 100%;
    line-height: 27px;
    font-size: 23px;
    color: var(--primary);
}

.vp-entrada-fecha{
    margin-top: 5px;
}

.franja{
    position: absolute;
    height: 100%;
    display: flex;
}

.banda-azul, .banda-celeste{
    height: 100%;
    width: 5px;
    z-index: 10;
   
    
    

    
}

.banda-azul{
    width: 15px;
    background-color: var(--primary);
}

.banda-celeste{
    width: 10px;
    background-color: #c51718;
}

.resumen-container{
    display: -webkit-box;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
}

.vista-grande:hover  .entrada-titulo{
    color: rgb(255, 255, 255) !important;
}

.vista-grande:hover .entrada-fecha{
    color: rgb(255, 255, 255) !important;

}

.vista-grande:hover .resumen-container{
    color: rgb(223, 251, 252)  !important;

}

.vista-pequeña:hover .vp-entrada-titulo{
    color: rgb(255, 255, 255) !important;
}



@media screen and (max-width: 1000px) {

    .vista-grande{
        display: none;
    }

    .vista-pequeña{
        display: block;
    }


}

@media screen and (max-width: 530px) {
    .vp-entrada-titulo{
        font-size: 17px;
        line-height: 22px;
    }

    .vp-entrada-fecha{
        font-size: 11px;
    }

    .vp-resumen-container{
        font-size: 13.5px;
    }
}