.notDisplayed{
    display: none;
}

.inmuebles-container{
    scroll-behavior: auto !important;
}
.inmuebles-h2{
    margin-left: 5%;
    margin-top: 50px;

}

.inmuebles-grid{


    margin: auto;
    margin-top: 50px;
    margin-bottom: 70px;
    height: auto;
    min-width: fit-content;
    max-width: 85%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(350px,1fr));
    grid-template-rows: auto;
    align-items: center;
    justify-items: center;
    gap: 24px;
    row-gap: 50px;
}

.grid-inmuebles{
    margin-top: 5vh;
    height: auto;
    width: 100%;

    display: grid;


}

.filtros-subcontainer{
    
    border-radius: 8px;
    width: 100%;
    height: fit-content;
    margin: auto;
    padding: 25px;
    font-weight: 600;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: space-evenly;

}

.filtros-selectores-container{
    min-width: 285px;
    width: fit-content;
    
    display: flex;
    padding-left: 8px;
    align-items: center;

    justify-content: space-between;
    margin-bottom: 15px;
}

.filtros-section{
    height: fit-content;
    min-width: fit-content;

    /* width: 30%; */
    /* background-color: red; */
}

.filtros-row-container{
    padding-left: 8px;
}

.filtros-row-title{
    padding-left: 8px ;
}

.filtros-caracts{
    justify-content: flex-start;

    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    column-gap: 50px;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
}

.filtros-container{
    /* background: linear-gradient(to top, rgb(255, 255, 255), rgba(215, 244, 252, 0.836)); */
    background: linear-gradient(to bottom ,rgb(232, 232, 232),rgb(250, 250, 250));
    /* background-color: rgb(232, 232, 232); */
    /* box-shadow: 0px 0px 10px rgba(39, 115, 160, 0.171); */
    /* position: sticky;
    top: 0;
    z-index: 2000; */
    overflow-y: hidden;
    transition: 2.3s ease;
    max-height: fit-content;
}

.filtros-submit-btn{
    display: flex;
    
    margin-bottom: 15px;
    width: 100%;
    justify-content: flex-end;
    
}

.filtros-btn{
    font-size: 17px;
    margin: 16px;
    margin-inline: auto;
    width: 30%;
}

.pestanya-filtros{
    margin-top: 30px;
    /* border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; */
    border-radius: 5px;
    width: 50%;
    margin-inline: auto;
    background-color: var(--primary);
    height: 50px;
    color: white;
    font-weight: 600;
    font-size: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    letter-spacing: 3px;
    
}

.pestanya-filtros:hover{
    background-color: var(--secondary);
    animation-name: up-n-down;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    
}

.arrow-down-icon{
    height: 38px;
    transition: 0.2s ease ;

}


.arrow-down-icon:hover{
    
    animation-name: up-n-down;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
}




.paginationBttns{
    display: flex;
    column-gap: 5px;
    list-style: none;
    justify-content: center;
    margin: auto;
    margin-bottom: 60px;
    min-width: fit-content;
    max-width: 85%;
    font-size: 15px;
    user-select: none;

}

.paginationBttns a {
    font-family: 'Poppins', sans-serif;
    padding: 5px;
    padding-inline: 15px;
    text-align: center;
    
   
    /* border: 1px solid var(--primary); */
    color: var(--primary);
    border-radius: 8px;
    cursor: pointer;
    
}


.paginationBttns a:hover{
    background-color: var(--turquesa);
    color: white;
    /* box-shadow: 0px 1px 2px  rgb(0, 6, 85); */
}

.paginationActive a {
    /* background: linear-gradient(to bottom, var(--primary), rgb(0, 88, 147)); */
    color: white;
    background-color: var(--secondary);
    /* box-shadow: 0px 1px 2px  rgb(0, 34, 82); */
}

.previousBttn, .nextBttn{
    color: white !important;
    background-color: var(--primary);
  
}

.sin-inmuebles{
    font-size: 30px;
    font-weight: 900;
    color: rgba(34, 24, 109, 0.319);
    width: fit-content;
    margin: auto;
  text-align: center;
  padding: 5%;
    display: flex;
    align-items: center;
    height: 300px;
    margin-bottom: 200px;
}



@keyframes up-n-down {

    0% {transform: translateY(-1px);
    }
    50% {transform: translateY(-3px);}
    100%{transform: translateY(-1px);}
}

@keyframes hideAndShow {

    to{
        max-height: 0;
    }
}

.ReactCollapse--collapse {
    transition: height 500ms;
  }

  .filtros-caracts-movil-view{
    display: none;       
}


  @media screen and (max-width: 1024px) {

    .filtros-section{
        
        
        
    }
    .filtros-subcontainer{
        justify-content: center;
    }

    .filtros-second-section{

    }

    .filtros-third-section{
        padding-left: 16px;
        
    }

    .filtros-third-subsection{
        column-gap: 117px;
    }

    .filtros-first-section{

    }


  }

  @media screen and (max-width: 862px) {

    .pestanya-filtros{
        
        width: 70%;
    }

    .inmuebles-grid{

        max-width: 90%;
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
        grid-template-rows: auto;
        align-items: center;
        justify-items: center;
        gap: 24px;
        row-gap: 50px;
    }



  }

  @media screen and (max-width: 768px) {
   
  }

  @media screen and (max-width: 700px) {
    .filtros-third-subsection{
        column-gap: 30px;
        max-width: 100%;
       
        /* justify-content: flex-start; */
    }
    .filtros-third-section{
        
        
        width: 100%;
    }

    .filtros-first-section{
        width: 100%;
    }

    .filtros-second-section{
        width: 100%;
    }
  }

  @media screen and (max-width: 425px) {

    .inmuebles-h2{
        text-align: center;
        margin-inline : 0;
    }

    .filtros-caracts-desktop-view{
        display: none;
    }

    .filtros-caracts-movil-view{
        display: flex;
    }

    

}

@media screen and (max-width: 375px) {

    .filtros-subcontainer{
        padding: 20px;
    }

   

}


