.add-inmueble-container{
    padding: 2.5%;
    padding-inline: 2.75%;
    background-color: rgb(240, 244, 255);
    min-height: 75vh;
    
}

.add-inmueble-form{
    
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    flex-wrap: wrap;
}

.add-inmueble-form-column{

    margin-right: 50px;
}

.add-inmueble-form-section{
    margin-bottom: 10px;
    width: 100%;
    
}

.add-inmueble-form-fields{
   display: flex;
   flex-wrap: wrap;
   column-gap: 20px;
   -webkit-column-gap: 20px;
}

.add-inmueble-form-caracteristicas{
    max-height: 260px;
    display: flex;
    flex-flow: column wrap;
    
    column-gap: 10px;
    -webkit-column-gap: 10px;
    margin-bottom: 25px;
    
}

.add-inmueble-form-select-container{
    display: flex;
    flex-direction: column;
    padding-inline: 8px;
    width: fit-content;
    margin-bottom: 15px;
    
}

.add-inmueble-form-subir-imgs-container{
  margin-top: 5px;
    height: 200px;
    width: 98%;
    margin-left: 8px;
    background-color: #00597c35;
    border-radius: 7px;
    border: 3px dashed #00042436;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
    outline: none;
    -webkit-transition: 0.2s ease-in-out;
}

.add-inmueble-form-imagenes-container{
  margin-left: 5px;
  margin-right: 20px;
  margin-top: 35px;
  margin-bottom: 30px;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit,minmax(190px, 1fr));
  height: auto;
  
}


.add-inmueble-form-img{
  height: 170px;
  width: 170px;
  object-fit: fill;
  border-radius: 3px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.538);
  z-index: -1;

}


.add-inmueble-form-img-pos{
  position: relative;
  top: 35px;
  left: 5px;
  background-color: var(--turquesa);
  width: 30px;
  height: 30px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Poppins';
  border-radius: 6px;
  font-weight: 600;
  border: 1px solid rgba(255, 255, 255, 0.441);
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.362);
}

.add-inmueble-form-img-del{
  position: absolute;
  z-index: 1;
  top: 30px;
  right: 0px;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
}

.add-inmueble-form-img-del:hover{
  transform: rotateZ(-15deg);
  -webkit-transform: rotateZ(-15deg) ;

}

.add-inmueble-form-img-container{
  transition: 0.2s ease;
  -webkit-transition:  0.2s ease;
  cursor: pointer;
  position: relative;
}

.add-inmueble-form-img-container:hover{
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
 }
 

.add-inmueble-form-space{
    width: 30%;
}

.add-inmueble-form-column-fWhidth{
  width: 100%;
}

.empty-h3{
    height: 29px;
}

.add-inmueble-form-estado{
  width: 90%;
  
  margin: auto;
  display: flex;
 
  align-items: center;
  column-gap: 40px;
  -webkit-column-gap: 40px;
  padding: 16px;
}

/* Apartado de selects */
/* The container must be positioned relative: */
.custom-select {
    position: relative;
    
    
  }
  
  .custom-select select {
    display: none; /*hide original SELECT element: */
  }
  
  .select-selected {
    background-color: rgb(255, 255, 255);
    border-radius: 4px !important;
    font-weight: 600;
    border: 1px solid rgb(187, 185, 185) !important;
  }
  
  /* Style the arrow inside the select element: */
  .select-selected:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: var(--primary) transparent transparent transparent;
    
  }
  
  /* Point the arrow upwards when the select box is open (active): */
  .select-selected.select-arrow-active:after {
    border-color: transparent transparent var(--primary) transparent;
    top: 7px;
    
  }
  
  /* style the items (options), including the selected item: */
  .select-items div,.select-selected {
    
    color: var(--primary);
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent rgba(118, 119, 119, 0.87) rgba(118, 119, 119, 0.87) rgba(118, 119, 119, 0.87);
    cursor: pointer;
  }
  
  /* Style items (options): */
  .select-items {
    position: absolute;
    
    background-color: rgb(244, 251, 255);
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
  }
  
  /* Hide the items when the select box is closed: */
  .select-hide {
    display: none;
  }
  
  .select-items div:hover, .same-as-selected {
    background-color: var(--turquesa);
    color: white !important;
    font-weight: 600;
    
  }

  .same-as-selected{
    background-color: var(--secondary);
  }



.linkToMaps{
  height: 48px;
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: var(--secondary);
  border-radius: 5px;
  font-weight: 600;
  padding: 10px;
  margin-block: 8px;
  transition: 0.25s ease;
  -webkit-transition: 0.25s ease;
}
  
.linkToMaps:hover{
  background-color: var(--turquesa);
}

@media screen and (max-width: 1274px) {
    .add-inmueble-form-space{
        width: 0%;
    }

    
}

@media screen and (max-width: 1274px) {
  .add-inmueble-form-space{
      width: 0%;
  }

  
}