.caract-card-container{
    height: 210px; 
    width: 370px;
    /* background-color: var(--turquesa); */
    background: linear-gradient(to top, var(--primary), var(--secondary), var(--turquesa));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 20px;
    color: aliceblue;
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;

    
    
}

.caract-card-container:hover{
    transform: translateX(45px) ;
    background: linear-gradient(to top, var(--primary), var(--secondary), var(--turquesa));
}



.caract-card-icon{
    height: 50px;
    width: 50px;
    margin-bottom: 10px;
}

.caract-card-title{

}

.caract-card-content{
    font-size: 14px;
    text-align: center;
}

@media screen and (max-width: 320px) {

    .caract-card-container{
        width: 290px;
    }

    .caract-card-title{
        font-size: 15px;
    }

    .caract-card-content{
        font-size: 13px;
    }

}