.notDisplayed{
    display: none;
}

.detalles-container{
    display: flex;
    display: -webkit-flex;
    gap: 40px;
    padding: 45px;
    justify-content: center;
  
}

/* parte izquierda -> imgs, precio, descr, caract, etc ⬇ */

.detalles-left-container{
    max-width: 1080px;
    width: 65%;
    min-width: 35%;
    
}

.detalles-img-wrapper{
   max-width: 100%;
   max-height: fit-content;
   position: relative;
   margin-bottom: 50px;
   
   
}

.contain-img{
    object-fit: cover;
    object-position: center;
    max-height: 607.5px;
    height: 100%;
}

.detalles-ref{
    font-family: 'Poppins', sans-serif;
}

.detalles-precio-anterior-container{
    display: flex;
    gap: 15px;
    color: red;
    letter-spacing: 1px;
    text-shadow: 0px 0px 3px rgba(242, 99, 255, 0.425);
}

.detalles-precio-container{
    margin-bottom: 15px;
    color: var(--primary);
    letter-spacing: 0.5px;
    text-shadow: 0px 0px 3px rgba(3, 97, 238, 0.582);
    font-size: 20px;
}

.detalles-precio-anterior{
    color: red;
    text-decoration: line-through;
  

}

.detalles-titulo-container{
    margin-bottom: 15px;
}

.detalles-main-specs-container{
    
    height: fit-content;
    width: 85%;
    column-gap: 50px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.main-spec-container, .main-spec-container-sec{
    
    width: 60px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 5px;
}

.main-spec-img{
    width: 100%;
    
}

.main-spec-data{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-align: center;
}



.detalles-descripcion-container{
    margin-bottom: 60px;
    width: 85%;
    letter-spacing: 0.5px;
}

.detalles-descripcion{
    text-align: justify;
}

.detalles-subtitle{
    margin-bottom: 25px;
}



.detalles-caracteristicas-container{
    margin-bottom: 60px;
    width: 75%;
    height: fit-content;
    display: flex;
    column-gap: 50px;
    row-gap: 20px;
    flex-wrap: wrap;
    font-size: 13px;
    text-align: center;
}

.main-spec-container-sec{
    width: 100px;
    justify-content: flex-start;
    line-height: 0.9rem;
}

.main-spec-img-small{
    width: 50%;
}

.detalles-eficiencia-container{
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 100px;
    row-gap: 0px;
}

.detalles-eficiencia-subcontainer{
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: -15px;
}

.efi-icon{
    height: 60px;
}

.detalles-mapa-container{
    width: 70%;
    height: 370px;
    
}


.boton-modal-contacto{
    background-color: var(--primary);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 4px;
    padding-inline: 12px;
    color: aliceblue;
    transform: rotateZ(-90deg);
    position: fixed;
    top: 80%;
    right: -35px;
    border: 2px solid #007bac;
    display: none;
    cursor: pointer;
    letter-spacing: 0.5px;
}

.espere-container{
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 1440px) {
   

    .contain-img{
        max-height: 580px;
    }

    .detalles-caracteristicas-container{
        width: 90%;
    }

    .detalles-mapa-container{
        width: 85%;
        height: 300px;
    }
    
}

@media screen and (max-width: 1024px) {
    .contain-img{
        max-height: 400px;
    }

    .main-spec-img{
        width: 85%;
    }

    .main-spec-container-sec{
        width: 90px;
    }


    .detalles-mapa-container{
        width: 90%;
        height: 300px;
    }
 
   
}

@media screen and (max-width: 850px) {

    .detalles-container{
        flex-direction: column;
        align-items: center;
    }

    .detalles-left-container{
        width: 100%;
        
    }

    .contain-img{
        max-height: 500px;
    }

    .detalles-main-specs-container{
        width: 95%;
        
    }

    .detalles-descripcion-container{
        width: 100%;
        
    }

    .detalles-mapa-container{
        width: 100%;
    }

    .main-spec-img{
        width: 80%;
    }

    .detalles-caracteristicas-container{
        width: 100%;
        column-gap: 40px;
        
        
    }
    .boton-modal-contacto{
        display: block;
    }
    
}

@media screen and (max-width: 768px) {
    
    .detalles-img-wrapper{
        width: 90vw;
        min-width: 90vw;
        transform: translate(-45px,-45px);
        margin-left: 5vw;
        margin-top: 5vw;
        

        
    }

   

    .detalles-caracteristicas-container{
        
        padding-inline: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(90px,1fr));
        column-gap: 15px;
        justify-items: center;
    
    }

    
}



@media screen and (max-width: 425px) {

    .detalles-container{
        padding: 30px;
    }
    .carousel.carousel-slider{
        border-radius: 0px;
    
    }

    .detalles-main-specs-container{
        
        column-gap: 6px;
        justify-content: space-around;
        width: 100%;
    }

    .main-spec-img{
        width: 70%;
    }

    .detalles-img-wrapper{
        width: 100vw;
        min-width: 100vw;
        margin-left: -75px;
        margin-top: -75px;
        transform: translate(45px, 45px);
        
        
    }

    .detalles-mapa-container{
        
        height: 270px;
    }

    .main-spec-container-sec{
        width: 80px;
        font-size: 12.5px;
    }

    
}

@media screen and (max-width: 375px) {

    .detalles-caracteristicas-container{
        
        padding-inline: 0px;
      
    
    }

    

    .detalles-mapa-container{
        
        height: 240px;
    }
}

@media screen and (max-width: 320px) {

    .main-spec-img{
        width: 65%;
    }
    .main-spec-data{
        font-size: 14px;
    }

    
}

@media screen and (max-width: 316px) {

    .boton-modal-contacto{
        right: -32px;
    }
}