

.form-contacto-container{
    background-color: rgb(238, 238, 238);
    border-radius: 10px;
    max-width: 400px;
    width: 30%;
    min-width: 310px;
    height: fit-content;
    padding: 22px;
    position: -webkit-sticky;
    position: sticky;
    top: 40px;
}

.detalles-contacto-form-title{
    text-align: center;
    color: var(--primary);
}

.detalles-contacto-form-subtitle{
    text-align: center;
    margin-bottom: 30px;
}

.detalles-contacto-form-tf, .detalles-contacto-form-txtarea, .detalles-contacto-form-btn{
    width: 100%;
    max-width: 100%;
    min-width: 200px;
    margin-bottom: 20px;
}

.detalles-contacto-form-txtarea{
    resize: vertical;
    height: 5rem;
    min-height: 5rem;
    max-height: 10rem;
}

.detalles-politica-priv-container{
    
    width: 100%;
    display: flex;
    padding-inline: 5px;
    gap: 8px;
    font-size: 13px;
    margin-bottom: 30px;
}

#form-contacto-checkbox{
    height: fit-content;
    width: fit-content;
}

.link-politica-priv{
    text-decoration: none;
    margin-left: 0px;
    color: var(--secondary);
}

.link-politica-priv:hover{
    text-decoration: underline;
}

.detalles-contacto-form-btn{
    margin-bottom: 0px;
}

.form-contacto-ok{
    display:  flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 850px) {
    .form-contacto-container{
       width: 100%;
       max-width: 80%;
    }
    
}

@media screen and (max-width: 425px) {
    .form-contacto-container{
        max-width: 100%;
        min-width: 295px;
    }

    .detalles-contacto-form-title{
        font-size: 18px;
        margin-bottom: 5px;
    }
}