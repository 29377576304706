.add-inmueble-field-container, .add-inmueble-field-container-row{
    display: flex;
    flex-direction: column;
    padding-inline: 8px;
 
    margin-bottom: 15px;
}

.add-inmueble-field-container-row{
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    align-items: center;
    height: 40px;
margin-bottom: 0;
}


