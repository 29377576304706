.articulo-container{
    
    /* background-color: aliceblue; */
    background: radial-gradient(#fff,rgba(159, 165, 189, 0.585));
    width: 100%;
    padding-inline: 22.5%;
    min-height: 88vh;
    padding-block: 100px;
    
}

.articulo-img-wrapper{
    margin: auto;
    height: 450px;
    width: 100%;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
    margin-bottom: 75px;
    box-shadow: 0px 10px 7px rgba(176, 182, 187, 0.827);

}

.articulo-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    
}

.articulo-titulo{
    color: var(--primary);
    font-size: 32px;
    
    width: 100%;
    margin-bottom: 15px;
    line-height: 35px;
    
}

.articulo-fecha{
    margin-bottom: 70px;
    font-size: 14px;
}

.articulo-container p {
    font-size: 17px;
  word-break: normal;
}

@media screen and (max-width: 1250px) {

    .articulo-container{
        padding-inline: 15%;
    }
}

@media screen and (max-width: 770px) {

    .articulo-container{
        padding-inline: 12%;
    }

    .articulo-img-wrapper{
        height: 250px;
        border-bottom-right-radius: 25px;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .articulo-titulo{
        font-size: 27px;
    }
}

@media screen and (max-width: 500px) {
    
    .articulo-container{
        padding-inline: 7%;
    }
}
