.login-container{
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    
}

.login-img{
    height: 120px;
    width: 120px;
    margin-bottom: 25px;
}

.login-visible-icon{
    height: 20px;
    width: 20px;
   
}

.pass-visible-btn{
    background-color: rgb(247, 246, 246);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.29);
    height: fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 21px;
    margin-left: 5px;
}

.pass-visible-btn:focus{
    background-color: rgb(247, 246, 246);
}

.pass-visible-btn:hover{
    background-color: var(--turquesa-sec);
}