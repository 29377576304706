:root{
  --primary: #020c2e;

  --secondary: #001845;
  --turquesa: #062e6c;
  --turquesa-sec: #0a46a1;
}

*{
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family:  'Raleway', sans-serif;

}

h1,h2,h3{
  font-family: 'Poppins', sans-serif ;
  font-weight: 500;
  

}

body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* width */
::-webkit-scrollbar {
  width: 16px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  
  
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
  border: 2px solid #f1f1f1;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 47, 109);
}

/* html{
  scroll-behavior: smooth;
} */

._1bpcM, ._2iWL7, ._1fOYS{
  z-index: 100000;
}



.suspense-component{
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center; 
  background: radial-gradient(#fff,rgba(159, 165, 189, 0.585));
}

.suspense-img{
  border-radius: 2px;
  animation: circleScale 1s infinite;
  
}

@keyframes circleScale {
	0% {transform: scale(1)  }
	50% { transform: scale(1.5) }
  100% { transform: scale(1) }
}