.motivo-card-container{
    display: flex;
    height: 130px;
    max-width: 360px;
    background: linear-gradient(135deg, #bce4f2cb,rgb(245, 253, 253), #cff0fcd8);
    padding: 20px;
    column-gap: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 6px;
    box-shadow: 0px 4px 4px #1000575e;
	 /* -0px -5px 6px  #10005789,
     -0px 5px 6px #10005789,
	 0px -5px 6px  #10005789 */
}

.motivo-card-icono{
    font-size: 38px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: var(--turquesa);
    
    min-width: 20px;
    
    display: flex;
    justify-content: center;
}

.motivo-card-content{
    font-family: 'Poppins', sans-serif;
    line-height: 1.2em;
    font-size: 15px;
    
}

.motivo-card-grid{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 35px;
    
    width: 90%;
}


@media screen and (max-width: 320px) {

    .motivo-card-content{
        font-size: 13px;
    }

}
