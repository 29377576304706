.dashboardinmb-container{
    height: fit-content;
    background-color: aliceblue;
    padding: 20px;
}

#dashboard-table{
    margin: auto;
    margin-top: 20px;
    margin-bottom: 40px;
    width: 90%;
    overflow-y: scroll;
    height: fit-content;
    
}

.dashboardinmb-header{
    height: 30px;
    font-size: 13px;
    background-color: var(--primary);
    color: white;
    text-align: start;
    
}

.col-estado{
    padding-left:3px;
    text-align: start;
    height: fit-content;
    width: 3%;
   
}

.col-ref{
    padding-left:3px;
    text-align: start;
    height: fit-content;
    width: 7%;
    
}

.col-img{
    padding-left:3px;
    text-align: start;
    height: fit-content;
    width: 8%;
    
}

.col-oportunidad{
    padding-left:3px;
    text-align: start;
    height: fit-content;
    width: 6%;
    
}

.col-precio{
    padding-left:3px;
    text-align: start;
    height: fit-content;
    width: 8%;
    
}

.col-ciudad{
    padding-left:3px;
    text-align: start;
    height: fit-content;
    width: 7%;
    
}

.col-zona{
    padding-left:3px;
    text-align: start;
    height: fit-content;
    width: 14%;
   
}

.col-domicilio{
    padding-left:3px;
    text-align: start;
    height: fit-content;
    width: 20%;
}

.col-metros{
    padding-left:3px;
    text-align: start;
    height: fit-content;
    width: 4%;
}

.col-habs{
    padding-left:3px;
    text-align: start;
    height: fit-content;
    width: 4%;
}

.col-banyo{
    padding-left:3px;
    text-align: start;
    height: fit-content;
    width: 4%;
}

.col-f-subido{
    padding-left:3px;
    text-align: start;
    height: fit-content;
    width: 5%;
}

.col-f-editado{
    padding-left:3px;
    text-align: start;
    height: fit-content;
    width: 5%;
}

.col-f-acciones{
    padding-left:3px;
    text-align: start;
    height: fit-content;
    width: 6%;
}

.dashboardinmb-row-data{
    z-index: 999 !important;
    background-color: rgb(240, 245, 246);
    font-size: 14px;
}

.dashboardinmb-row-data-impar{
    z-index: 999 !important;
    background-color: rgb(250, 255, 254);
    font-size: 14px;
}


.thumbnail-cell{
    padding: 1px !important;
    padding-top: 7px !important;
    padding-inline: 2px !important;
}

.dashboardinmb-cell{
    padding: 3px;
    font-family: 'Poppins', sans-serif;
}

.fecha-cell{
    font-size: 12px;
}

.numbers-cell{
  
}

.dashboardinmb-thumbnail{
    width: 180px;
    object-fit: cover;
    object-position: center;
    height: 90px;
    margin-inline: 3px;
    
}

.eliminar-inmueble-div{
    position: relative;
    transition: 0.2s ease;
    -webkit-transition: 0.2s ease;
    z-index: 1000 !important;
}

.eliminar-inmueble-div:hover{
    transform: rotateZ(15deg);
    -webkit-transform: rotateZ(15deg);
}

.dashboardinmb-row-data:hover, .dashboardinmb-row-data-impar:hover{
    background-color: var(--turquesa);
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    color: white;
    cursor: pointer;
}

.leyenda-tabla{
    margin-right: 5%;
    display: flex;
    align-items: center;
    
}