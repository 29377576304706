.slider-container{
    
    margin-top: 50px;
    height: fit-content;
    max-height: 435px;
    width: 580px;
    
    
    margin-inline: auto;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #000000ad;
    
}

/* TARJETA QUE BAJA PARA CAMBIAR PRECIO INICIA AQUI */
/* .cover-details-container {
    position: absolute;
    background: linear-gradient(to bottom, #a1c6e9 , #b4d6f7);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 5px;
    
}

.cover-details-logo{
    position: relative;
    height: 100px;
} */

/* Acaba aqui */

/* Posicion central baja */
/* .outstanding-details-container{
    
    padding-bottom: 5px;
    
    background-color: rgb(244, 254, 255);
    height: 140px;
    width: 380px;
    position: absolute;
    left: 210px;
    top: 400px;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #0000005e;
    z-index: 10;
    cursor: pointer;
    
} */

/* Posición derecha baja */
/* .outstanding-details-container{
    
    padding-bottom: 5px;
    
    background-color: rgb(244, 254, 255);
    height: 140px;
    width: 380px;
    position: absolute;
    left: 420px;
    top: 310px;
    border-radius: 5px;
    
    
    cursor: pointer;
    
} */

/* Esta parte es general en el formato tarjeta INICIA AQUI */
/* .outstanding-price{
    padding-top: 3px;
    width: 280px;
    margin: auto;
    text-align: center;
    font-size: 30px;
    color: white;
    background-color: #001845ee;
    border-bottom-left-radius: 5px ;
    border-bottom-right-radius: 5px ;
    margin-bottom: 5px;
    letter-spacing: 1px;
    box-shadow: 0px 1px 5px #00184557;
    
    
}

.outstanding-icons{
    width: 27px;
    margin-right: 8px;
}

.outstanding-main-specs{
    display: flex;
    align-items: center;
    max-width: 100px;
    font-weight: 600;
    font-size: 16px;
}

.outstanding-title{
    width: 100%;
    height: 1.5rem;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-inline: 8px;
    font-size: 19px;
    
    text-align: center;
    
    margin-top: 15px;
    
}

.main-specs-row{
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
    padding-inline: 50px;
} */
/* ACABA AQUI EL FORMATO TARJETA */


/* Formato antes de modificar, ubicado a la dcha */
/* .outstanding-details-container{
    padding: 15px;
    background-color: rgb(255, 255, 255);
    height: 170px;
    width: 355.56px;
    position: absolute;
    left: 565px;
    top: 375px;
    border-radius: 5px;
    
    box-shadow: 0px 2px 10px #000000ad;
    z-index: 10;
    
} */

/* .outstanding-price{
    width: fit-content;
    margin: auto;
    font-size: 40px;
    color: var(--primary);
}





.outstanding-icons{
    width: 35px;
    margin-right: 8px;
}

.outstanding-main-specs{
    display: flex;
    align-items: center;
    max-width: 100px;
    
    font-weight: 600;
}

.outstanding-title{
    width: 100%;
    height: 1.5rem;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-inline: 8px;
    font-size: 15px;
}

.main-specs-row{
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
} */

.fill{
    width: 100%;
    height: 435px;
    object-fit: cover;
    object-position: center !important;
    border-radius: 5px;
}

/* Overriding carousel classes */

.carousel.carousel-slider{
    /* background-color: var(--secondary); */
    /* background: linear-gradient(to bottom, rgb(0, 22, 51), rgb(16, 0, 34) ); */
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #000000ad;
    padding: 0;
    margin: 0;
    cursor: pointer;
    
    
    overflow: hidden;
}


/* Visualización alternativa con precio arriba y datos abajo */
.outstanding-price{
    width: 250px;
    /* background-color: var(--primary); */
    /* background-color: white; */
    background-color: rgb(255 255 255 / 76%);
    border: 3px solid #ffffff;
    border-radius: 12px;
    
    padding: 5px;
    padding-top: 10px;
    color: var(--primary);
    position: absolute;
    font-size: 35px;
    z-index: 10;
    top: -10px;
    left: 165px;
    box-shadow: 0px 3px 7px rgb(7, 7, 58);
    /* text-shadow: 0px 0px 7px rgba(20, 135, 243, 0.774); */
    letter-spacing: 0.5px;
}

.outstanding-details-container{
    position: absolute;
    width: 550px;
    height: 50px;
    top: 399px;
    left: 15px;
    background-color: rgba(255, 255, 255, 0.849);
    border: 3px solid var(--primary);
    border-radius: 12px;
    padding-bottom: 40px;
    
    padding: 5px;
    padding-top: 3px;
    display: flex;
    padding-inline: 20px;
    justify-content: space-between;
    box-shadow: 0px 3px 7px rgb(7, 7, 58);
    font-weight: 600;
    
}

.outstanding-opportunity{
    font-size: 8px;
    font-weight: 600;
    color: var(--primary);
    text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.267);
    width: 100px;
    height: 80px;
    background-color: var(--primary);
    
    border: 3px solid white;
    margin-left: -23px ;
    padding-top: 3px;
    margin-top: -7px;
    border-radius: 12px;
    border-top-right-radius: 0px;
    color: white;
    /* text-shadow: 0px 0px 7px rgba(20, 135, 243, 0.774); */
}

.outstanding-title{
    
    padding-inline: 35px;
    padding-left: 20px;
    padding-top: 5px;
    width: 260px;
    
    height: 40px;
    text-align: left;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    
}

.main-specs-row{
    
    width: 230px;
    
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: -2px;
    padding-right: 40px;
}

.outstanding-main-specs{
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    font-size: 17px;
    
    margin-top: -6.5px;
    margin-right: 5px;
    font-family: 'Poppins', sans-serif;
}

.outstanding-icons{
    width: 26px !important;
    height: 26px !important;
    
    margin-right: 9px;
}

#bed-icon{
    margin-top: 2px;
}

.slider-vacio-container{
    background: linear-gradient(180deg, rgba(222, 239, 253, 0.497), rgba(166, 207, 253, 0.601));
    height: 300px;
    row-gap: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 664px) {

    .fill{
        height: 315px;
    }
    
    .slider-container{
        width: 420px;
    }

    .outstanding-price{
        width: 170px;
        font-size: 25px;
        padding: 0px;
        padding-top: 10px;
        left: 125px;
    }

    .outstanding-details-container{
        width: 420px;
        top: 280px;
        left: 0px;
    }

    .outstanding-opportunity{
        font-size: 6px;
        padding-top: 7px;
    }

    

    .outstanding-title{
        width: 260px;
        padding-top: 6px;
        font-size: 12px;
        
        padding-inline: 0px;
        padding-left: 10px;
    }
    
    .outstanding-main-specs{
        font-size: 13px;
        margin-right: 0px;
        margin-top: -6px;
    }
    .main-specs-row{
        padding-right: 0px;
    }

    .outstanding-icons{
        width: 20px !important;
        height: 20px !important;
        margin-right: 5px;
    }
}

@media screen and (max-width: 480px) {

    .fill{
        height: 277px;
    }
    
    .slider-container{
        width: 370px;
    }

    .outstanding-price{
        width: 140px;
        font-size: 20px;
        padding: 0px;
        padding-top: 7px;
        left: 115px;
    }

    .outstanding-details-container{
        width: 370px;
        top: 245px;
        left: 0px;
    }

    .outstanding-opportunity{
        font-size: 5.5px;
        padding-top: 7px;
        width: 85px;
    }

    

    .outstanding-title{
        width: 260px;
        padding-top: 6px;
        font-size: 11px;
        padding-inline: 0px;
        padding-left: 8px;
    }
    
    .outstanding-main-specs{
        font-size: 11px;
        margin-right: 0px;
        margin-top: -7px;
    }
    .main-specs-row{
        width: fit-content;
        padding-right: 0px;
        gap: 10px;
    }

    .outstanding-icons{
        width: 16px !important;
        height: 16px !important;
        margin-right: 5px;
    }

    .slider-vacio-container{
        font-size: 12px;
        
    }

}

@media screen and (max-width: 410px) {

    .fill{
        height: 212px;
    }
    
    .slider-container{
        width: 284px;
    }

    .outstanding-price{
        width: 110px;
        font-size: 15px;
        padding: 0px;
        padding-top: 7px;
        left: 87px;
    }

    .outstanding-details-container{
        width: 284px;
        top: 190px;
        left: 0px;
    }

    .outstanding-opportunity{
        font-size: 4.5px;
        padding-top: 4px;
        width: 110px;
    }

    

    .outstanding-title{
        width: 260px;
        padding-top: 2px;
        font-size: 9px;
        padding-inline: 0px;
        padding-left: 8px;
    }
    
    .outstanding-main-specs{
        font-size: 9px;
        margin-right: 0px;
        margin-top: -19px;
    }
    .main-specs-row{
        width: fit-content;
        padding-right: 0px;
        gap: 7px;
    }

    .outstanding-icons{
        width: 13px !important;
        height: 13px !important;
        margin-right: 3px;
    }

}