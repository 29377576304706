
.buscador-container{
    margin-top: 30px;
    height: fit-content;
    width: fit-content;
    background-color: #020c2e;

    border-radius: 12px;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: space-around;
    column-gap: 10px;
    -webkit-column-gap: 10px;
    row-gap: 15px;
    align-content: center;
    padding: 25px;
    position: relative;
    
   
}
.buscador-opcion-container{
    height: fit-content;
    width: fit-content;

    position: relative;
}

.buscador-opcion-container-select{

}

#buscador-opciones-operacion{
  
}

.desplegable-operacion{
    z-index: 50;
}

.buscador-opcion{
    height: 57px;
    
    width: auto;
    min-width: fit-content;
    padding: 16px;
    background-color: white;
    color: rgb(1, 7, 41);
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: space-between;
    gap: 15px;
    
    
}

.buscador-tf{
    height: 57px;
    display: flex;
    align-items: baseline;
    width: auto;
    min-width: fit-content;
    padding: 16px;
    background-color: white;
    color: rgb(1, 7, 41);
    border-radius: 10px;
    
}

.buscador-container-dropdowns{
    height: 65px;
    width: 70vw;
    background-color: rgb(1, 7, 41);
    border-radius: 12px;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 2fr 0.5fr;
    gap: 10px;
    padding-inline: 25px;
    position: relative;
}

.buscador-opcion{
    width: 140px;
}

#desplegables-container{
 
 position: relative;

}


.desplegable{
    margin-top: 3px;
    position: absolute;
    
    background-color: white;
    color: black;
    border-radius: 10px;
    overflow: hidden;
    width: 140px;
    box-shadow: 0px 5px 15px black;
    
    
}


.desplegable-item{
    text-overflow: ellipsis;
    overflow: hidden;
    padding-block: 5px;
    padding-inline: 12px;
    width: 100%;
    
    
    
}

.separador{
   
   border: 1px solid rgba(5, 98, 110, 0.096);
    width: 100%;
}

.desplegable-item:hover, .desplegable-item:active{
    background-color: rgb(10, 168, 189);
    color: white;
    
}

.buscador-tf{
    outline: none;
    border: none;
    font-size: 16px;
   
    
}

.down-icon{
    height: 20px;
    width: auto;
    margin-top: 2px;
}

.lupa-icon{
    height: 23px;
    
    
}

.btn-buscar{
    height: 57px;
    padding-inline: 15px;
    font-size: 18px;
    cursor: pointer;
}

.hidden{
    visibility: hidden;
}







  @media screen and (max-width: 768px) {

    .buscador-container{
        width: 435px;
    }

    .buscador-opcion-container-select{
        width: 48%;
    }

    .buscador-opcion{
        width: 100%;
    }

    .buscador-tf{
        width: 100%;
 
    }

    .btn-buscar{
        width: 100%;
        justify-content: center;
        column-gap: 5px;
        -webkit-column-gap: 5px;
    }

  }

  @media screen and (max-width: 450px) {

    .buscador-tf, .btn-buscar, .buscador-opcion, .buscador-opcion-container-select{
        height: 48px;
    }

    .buscador-container{
        width: 90%;
    }

    .buscador-opcion-container-select{
        width: 100%;
    }

    

  }
