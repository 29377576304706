.hidden{
    display: hidden;
}

.card-container{
    
    height: 420px;
    
    width: 400px;
    
    border-radius: 8px;
    box-shadow: 0px 7px 14px -2px rgba(12, 31, 92, 0.247);
    overflow: hidden;
    padding: 0;
    transition: all .2s ease-in-out;
    cursor: pointer;
    position: relative;
    
}

.datos-inmueble-container{
    background: linear-gradient(to bottom, #fff, rgb(237, 247, 255));
    height: 38%;
    
    padding: 10px;
    padding-inline: 20px;
    overflow: hidden;
    /* border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px; */
}

.card-container:hover{
    transform: translateY(-3px);
    box-shadow: 0px 8px 14px -2px rgba(12, 31, 92, 0.37);
}


.inmueble-oportunidad-container{
    width: 100px;
    height: 35px;
    /* background-color: var(--primary); */
    background-color: rgb(255 255 255 / 76%);
    border-radius: 5px;
    /* border: 3px solid #007bac; */
    border: 3px solid #ffffff;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translate(-50%,0);
    text-align: center;
    padding-top: 2px;
    color: var(--primary);
    box-shadow: 0px 0px 6px rgb(7, 7, 58);
    /* text-shadow: 0px 0px 7px rgba(20, 135, 243, 0.774); */
    font-size: 8px;

}

.precio-anterior{
    position: relative;
    text-decoration: line-through;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: rgb(255, 51, 51);
    text-shadow: 0px 0px 5px rgba(242, 99, 255, 0.774);
    height: 20px;
    padding-top: 10px;
    top: -10px;
}

.precio-actual{
    position: relative;
    top: -22px;
    height: 45px;
    padding-top: 10px;
}

.inmueble-precio{
    width: 250px;
    max-height: 65px;
    margin: auto;
    position: relative;
    top: -15px;
    /* background-color: var(--primary); */
    /* color: white; */
    color: var(--secondary);
    border-radius: 5px;
    /* border: 3px solid #007bac; */
    padding-top: 15px;
    text-align: center;
    /* text-shadow: 0px 0px 5px rgba(59, 242, 255, 0.774); */
    text-shadow: 0px 4px 5px rgba(40, 84, 154, 0.215);
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.inmueble-zona{
    text-align: center;
    
    
    font-weight: 600;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
}

.inmueble-specs-row{
    
    height: 40px;
    width: 60%;
    margin: auto;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 40px;
}

.inmueble-specs{
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    
    

}

.inmueble-icons{
    height: 30px;
    margin-right: 10px;
}



.inmueble-imgs-wrapper{
    height: 62%;
}

/* --------CLASES DEL SLIDER-------- */


.image-gallery,.image-gallery-using-mouse{
    height: 100%;
    width: 100%;
    /* z-index: -2;  */
    
 /* object-fit: cover !important;
    object-position: center; */
}

.image-gallery-content, .image-gallery-slide-wrapper, .image-gallery-swipe, .image-gallery-slides, .image-gallery-slide{
    height: 100%;
  
    
}

.image-gallery-svg{
    width: 30px  !important;
    transition: 0.1s ease !important;
}

.image-gallery-svg:hover{
    color: rgb(10, 168, 189);
    width: 45px !important;
}



.image-gallery-image  {
    object-fit: fill !important;
    object-position: center !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 100%;
    height: 100%;

    z-index: -2;
}


@media screen and (max-width: 862px) {

    .card-container{
        width: 345px;
    }

  }

@media screen and (max-width: 425px) {

    .card-container{
        width: 95%;
    }

    

}