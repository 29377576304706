.politica-cookies-container{
    padding-inline: 15%;
    padding-block: 5%;
}

th{
    background-color: var(--primary);
    color: white;
    margin-inline: 10px;
    padding: 5px;
    
}
td{
    padding: 10px;
}

tbody{
    background-color: rgb(243, 247, 251);
}